//fonts
@font-face {
    font-family: 'Avenir Demi';
    src: url("../fonts/AvenirNextforABG-Demi.otf") format("opentype");
}

;
@font-face {
    font-family: 'Avenir Bold';
    src: url("../fonts/AvenirNextforABG-Bold.otf") format("opentype");
}

;
@font-face {
    font-family: 'Avenir Regular';
    src: url("../fonts/AvenirNextforABG-Regular.otf") format("opentype");
}



;
$main: 'Avenir Regular', "Lucida Sans Unicode",
sans-serif;
$demi: 'Avenir Demi',"Lucida Sans Unicode",
sans-serif;
$bold: 'Avenir Bold',"Lucida Sans Unicode",
sans-serif;
$text: 'Lato', 'Lucida Sans Unicode', sans-serif;
$text2: Tahoma, Geneva, sans-serif;

//colors:
$my-black: #272727;
$logo: rgb(35,67,140);
$dark:  rgb(168,168,168);
$light: rgb(207,207,207);
$super: rgb(238, 238, 238);
//media queries
$phone:"(max-width: 719px)";
$phone-landscape: "(max-width: 719px) and (orientation: landscape)";
$tablet:"(min-width: 720px) and(max-width: 1023px)";
$desktop: "(min-width: 1024px) and (max-width: 1800px)";
$large:"(min-width: 1801px)";
